
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import '../assets/slider/slider-min.jpg';

const popup = localStorage.getItem('akgul-popupIsShow');
if(!popup){
    Swal.fire({
        imageUrl: 'https://akgulpilic.com.tr/uploads/alisveris-karti-pop.jpg',
        imageWidth: 400,
        showConfirmButton:false,
        showCloseButton:true
    }).then(() => {
        localStorage.setItem('akgul-popupIsShow', 'true');
    })
}